.rightBar {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  height: 100%;
}

.rightBar .searchContainer {
  z-index: 3;
  position: sticky;
  top: 0px;
  padding: 5px 0;
  background-color: #fff;
}

.rightBar .trends,
.rightBar .accounts {
  display: flex;
  flex-direction: column;
  background-color: rgb(249, 249, 249);
  width: 100%;
  height: fit-content;
  border-radius: 15px;
  padding: 0;
  padding-top: 10px;
  overflow: hidden;
}

.rightBar .trends .trendItem:last-child {
  padding-bottom: 20px;
}

.rightBar .accounts .accountModule:last-child {
  padding-bottom: 15px;
}

.rightBar .trends > .title,
.rightBar .accounts > .title {
  font-weight: 700;
  font-size: 2.1rem;
  margin-bottom: 12.5px;
  margin-left: 15px;
}

.whItems {
  display: flex;
  flex-direction: column;
}

.accounts .title {
  padding-bottom: 5px;
}

.accounts.only {
  margin-top: 7.5px;
}

.accountItems {
  display: flex;
  flex-direction: column;
}

.copyright {
  font-size: 1.3rem;
  color: var(--light-black);
  display: flex;
  column-gap: 10px;
  row-gap: 5px;
  padding: 0 15px;
  flex-wrap: wrap;
}

.forcewrap {
  width: 100%;
}

.copyright a:hover {
  text-decoration: underline;
}

.rightBarMain {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: fit-content;
  position: sticky;
  padding-bottom: 75px;
  top: -160px;
}

.rightBarMain.only {
  margin-top: 15px;
  top: -175px;
}

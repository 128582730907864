.navBar {
  padding-top: 17.5px;
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-left: 0;
  position: sticky;
  top: 0;
  align-self: flex-start;
  height: 100vh;
}

.navBar .logo {
  margin-left: 15px;
  width: 28.5px;
  padding-bottom: 5px;
}

.navButton {
  display: flex;
  gap: 15px;
  align-items: center;
  width: fit-content;
  padding: 5px 10px;
  padding-right: 20px;
  transition: 0.15s;
  border-radius: 1000px;
}

.navButton:hover {
  cursor: pointer;
  background-color: rgb(231, 231, 231);
}

.navButton.active .navName {
  font-weight: 700;
}

.navIcon {
  width: 35px;
}

.navName {
  font-size: 2.05rem;
  letter-spacing: 0.5px;
}

.navBar .chirpButton img {
  display: none;
}

@media (max-width: 1250px) {
  .navBar {
    justify-self: center;
    width: 80px;
  }

  .navBar .logo {
    margin: 0;
    align-self: center;
  }

  .navName {
    display: none;
  }

  .navBar .chirpButton {
    width: 52px;
    border-radius: 10000px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navBar .chirpButton p {
    display: none;
  }

  .navButton {
    margin: auto;
    padding-right: 10px;
  }

  .navBar .chirpButton img {
    display: block;
    width: 28px;
    filter: invert(100%);
  }
}

.accountModule {
  font-size: 1.5rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 80px 1fr 110px;
  padding: 10px 0;
}

.accountModule:hover {
  cursor: pointer;
  background-color: rgb(241, 241, 241);
}

.accountInfo {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 7px;
  overflow: hidden;
  white-space: nowrap;
}

.accountInfo .name {
  font-weight: 700;
  width: fit-content;
}

.accountInfo .name:hover {
  text-decoration: underline;
}

.accountInfo .at {
  color: var(--light-black);
  width: fit-content;
}

.accountModule button {
  align-self: center;
  justify-self: center;
  font-size: 1.4rem;
  font-weight: 700;
  height: 3.25rem;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--light-grey);
}

.accountModule .followButton {
  background-color: black;
  color: white;
  width: 77.5px;
}

.accountModule .unfollowButton {
  background-color: transparent;
  color: black;
  width: 80px;
}

.accountModule .followButton:hover {
  cursor: pointer;
  background-color: #262626;
}

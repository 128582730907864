header:not(.homePage header, .searchPage header, .explorePage header) {
  padding: 0 10px;
  display: flex;
  gap: 15px;
  align-items: center;
  height: 52.5px;
}

header .info {
  height: 100%;
  padding: 6px 0;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

header .top {
  font-size: 2rem;
  font-weight: 700;
}

header .bottom {
  font-size: 1.4rem;
  color: var(--light-black);
}

header img {
  width: 22px;
}

.loading {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 4;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.noHeaderLoading {
  top: 52.5px;
}

.thickHeaderLoading {
  top: 105px;
}

.homePageLoading {
  top: calc(115px + 104px);
}

.homePageLoading .loadingIcon {
  margin-top: 100px;
}

.profileTabLoading {
  top: 546px;
}

.profileTabLoading .loadingIcon {
  margin-top: 25px;
}

.noHeaderLoading .loadingIcon {
  margin-top: 150px;
}

.fullMainLoading .loadingIcon {
  margin-top: calc(150px + 51.5px);
}

.thickHeaderLoading .loadingIcon {
  margin-top: calc(150px + 104px);
}

.loading .loadingIcon {
  width: 50px;
}

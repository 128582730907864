.chirpModule {
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-template-rows: min-content 50px;
  border-bottom: 1px solid var(--light-grey);
  padding: 0 15px;
  margin-top: 7.5px;
}

.chirpModule .chirpWriting {
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 70px 1fr;
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  position: relative;
}

.uploadedImage {
  position: relative;
  grid-row: -2 / -1;
  grid-column: -2 / -1;
  height: fit-content;
  max-height: 1000px;
  width: fit-content;
  max-width: 100%;
  align-self: center;
  margin-top: 8px;
  padding-bottom: 16px;
}

.uploadedImage > .closeContainer > img {
  position: absolute;
  background-color: #dbdbdbef;
  top: 5px;
  left: 5px;
  border-radius: 1000px;
  display: flex;
  filter: invert(100%);
  width: 32px;
  padding: 6px;
}

.uploadedImage > .closeContainer:hover {
  cursor: pointer;
}

.uploadedImage > .thePic {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
}

.chirpWriting .profilePic {
  align-self: flex-start;
  width: 48px;
  height: 48px;
  border-radius: 1000px;
}

.chirpWriting .inputContainer {
  overflow: auto;
}

.chirpWriting #chirpInput {
  display: inline-block;
  font-family: 'Chirp', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
  height: 54px;
  font-size: 2rem;
  padding-top: 12px;
  padding-bottom: 12px;
  border: none;
  outline: none;
  resize: none;
  overflow: hidden;
  white-space: pre-wrap;
  line-height: 1.25;
}

#chirpInput:hover {
  cursor: text;
}

#chirpInput.invis {
  position: relative;
  z-index: 2;
  color: transparent;
  caret-color: black;
}

#chirpInput.copy {
  position: absolute;
  left: 70px;
  width: calc(100% - 70px);
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
}

#chirpInput > p {
  display: inline;
}

#chirpInput > span {
  display: inline;
}

#chirpInput > .hashtag {
  display: inline;
  color: var(--blue);
}
#chirpInput > span:hover {
  cursor: pointer;
  text-decoration: underline;
}

#chirpInput:empty::before {
  content: attr(data-placeholder);
  display: block;
  height: 100%;
  color: var(--light-black);
}

.chirpModule .toolbar {
  grid-row: -2 / -1;
  grid-column: 2 / -1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chirpModule .toolbar .icons {
  margin-left: -7.5px;
  display: flex;
  align-items: center;
  gap: 2.5px;
}

.iconContainer {
  position: relative;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  padding: 7.5px;
  transition: 0.2s;
}

.icon {
  width: 100%;
}

.gif.icon {
  width: 22px;
  filter: invert(23%) sepia(87%) saturate(3655%) hue-rotate(267deg)
    brightness(89%) contrast(116%);
}

.iconContainer:hover {
  cursor: pointer;
  background-color: var(--blue-hover-white);
}

.toolbar .characterCount {
  font-size: 1.3rem;
  margin-left: auto;
  margin-right: 15px;
  color: var(--blue);
}

.chirpModuleContainer.overlay {
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 11;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  padding-top: 50px;
  padding-bottom: 50px;
}

.overlay .close {
  position: absolute;
  width: 34px;
  padding: 6px;
  top: 10px;
  left: 10px;
  border-radius: 1000px;
  transition: 0.2s;
}

.overlay > .chirpModule > .close:hover {
  cursor: pointer;
  background-color: var(--light-grey);
}

.overlay .chirpModule {
  position: relative;
  background-color: #fff;
  padding-top: 60px;
  border-radius: 15px;
  width: 600px;
}

.overlay .chirpWriting {
  border-bottom: 1px solid var(--light-grey);
}

.overlay #chirpInput {
  min-height: 150px;
  grid-template-rows: 150px 1fr;
}

#imageInput {
  position: absolute;
  opacity: 0;
  display: block;
  border-radius: 1000px;
  height: 100%;
  width: 100%;
}

#imageInput:hover {
  cursor: pointer;
}

#imageInput::file-selector-button {
  display: none;
}

.hashtag {
  color: var(--blue);
}

.homePage {
  width: 600px;
}

.homePage header {
  grid-template-columns: repeat(2, 1fr);
}

.homePage .subheader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  transition: 0.2s;
  user-select: none;
}

.homePage .subheader:hover {
  cursor: pointer;
  background-color: rgb(241, 241, 241);
}

.homePage .subheader.foryou {
  grid-row: -2 / -1;
  grid-column: 1 / 2;
}
.homePage .subheader.following {
  grid-row: -2 / -1;
  grid-column: -2 / -1;
}

.indicator {
  animation-timing-function: ease;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

.indicator.foryou {
  animation-name: tabSwitch;
}

.indicator.following {
  animation-name: tabSwitch2;
}

@keyframes tabSwitch {
  0% {
    left: 420px;
  }
  10% {
    width: 55px;
  }
  20% {
    width: 20px;
  }
  100% {
    width: 55px;
    left: 122px;
  }
}
@keyframes tabSwitch2 {
  0% {
    left: 122px;
  }
  10% {
    width: 55px;
  }
  20% {
    width: 20px;
  }
  100% {
    width: 55px;
    left: 420px;
  }
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

a {
  text-decoration: none;
  color: inherit;
}

html,
body,
div,
button,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background-color: transparent;
  border: none;
}

/*////////////// END OF CSS RESET ////////////////*/

@font-face {
  font-family: 'Chirp';
  src: url('../fonts/Chirp-Regular.woff');
  font-weight: 400;
}
@font-face {
  font-family: 'Chirp';
  src: url('../fonts/Chirp-Medium.woff');
  font-weight: 700;
}
@font-face {
  font-family: 'Chirp';
  src: url('../fonts/Chirp-Heavy.woff');
  font-weight: 600;
}
@font-face {
  font-family: 'Chirp';
  src: url('../fonts/Chirp-Bold.woff');
  font-weight: 800;
}
@font-face {
  font-family: 'Apple';
  src: url('../fonts/SFPRODISPLAYMEDIUM.OTF');
}
@font-face {
  font-family: 'Google';
  src: url('../fonts/productsans.ttf');
}

:root {
  font-family: 'Chirp', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 10px;

  --blue: hsl(275, 89%, 53%);
  --blue-hover-pure: hsl(275, 79%, 43%);
  --blue-hover: hsla(275, 90%, 84%, 0.118);
  --blue-hover-border: hsl(275, 49%, 85%);
  --blue-hover-white: hsla(275, 89%, 53%, 0.118);
  --light-black: rgb(101, 101, 101);
  --light-grey: rgb(231, 231, 231);
  --white-hover: rgb(249, 249, 249);
  --black-hover: #262626;
}
body {
  display: flex;
  justify-content: center;
}

#root {
  margin: auto;
  display: grid;
  grid-template-columns: 270px 600px 380px;
}

.clickDetector {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 50;
  height: 100%;
  width: 100%;
}

.logo {
  filter: invert(23%) sepia(97%) saturate(3273%) hue-rotate(267deg)
    brightness(89%) contrast(117%);
}

.page {
  position: relative;
}

@media (max-width: 1250px) {
  #root {
    grid-template-columns: 80px 600px 380px;
  }
}

.signInBanner {
  position: fixed;
  z-index: 5;
  height: 73px;
  width: 100vw;
  background-color: var(--blue);
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 300px;
}

.text {
  color: white;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.topText {
  font-size: 2.5rem;
  font-weight: 700;
}

.bottom {
  font-size: 1.5rem;
}

.buttons {
  display: flex;
  gap: 10px;
}

.buttons button {
  border: none;
  width: 85px;
  height: 37.5px;
  border-radius: 1000px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  transition: 0.2s;
}

.buttons .logIn {
  color: white;
  background-color: transparent;
  border: 1px solid white;
}

.buttons .logIn:hover {
  cursor: pointer;
  background-color: var(--blue-hover);
}

.buttons .signUp {
  border: 1px solid var(--light-grey);
  background-color: #fff;
}

.buttons .signUp:hover {
  cursor: pointer;
  background-color: var(--light-grey);
}

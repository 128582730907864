.profilePicContainer {
  position: relative;
  justify-self: center;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.imageOverlay {
  transition: 0.2s;
}

.profilePicContainer:hover > .imageOverlay {
  background-color: rgba(0, 0, 0, 0.157);
  border-radius: 1000px;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.profilePicContainer > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  grid-column: 1 / 2;
  grid-column: 1 / -1;
}

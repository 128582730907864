.searchBar {
  position: relative;
  grid-column: -2 / -1;
  display: flex;
  align-items: center;
  background-color: hsl(193, 16%, 95%);
  border-radius: 1000px;
  gap: 15px;
  height: 45px;
  margin-top: 0px;
  color: var(--light-black);
}

.searchBar > img {
  position: relative;
  z-index: 10;
  margin-left: 15px;
  width: 25px;
  filter: opacity(50%);
}

.searchBar > input {
  height: 100%;
  width: 100%;
  border-radius: 1000px;
  font-size: 1.6rem;
  padding-left: 50px;
  border: none;
  position: absolute;
  background-color: transparent;
  font-family: 'Chirp', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.searchBar > input:focus {
  background-color: white;
  outline: 1px solid var(--blue);
}

.searchBar > input:focus + img {
  filter: invert(56%) sepia(36%) saturate(5072%) hue-rotate(176deg)
    brightness(100%) contrast(91%);
}

.page {
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--light-grey);
  border-right: 1px solid var(--light-grey);
  height: max(100%, 100vh);
}

header {
  background-color: rgba(255, 255, 255, 0.85);
  position: sticky;
  z-index: 3;
  top: 0;
  height: 105px;
  display: grid;
  grid-template-rows: 52.5px 52.5px;
  border-bottom: 1px solid var(--light-grey);
  backdrop-filter: blur(10px);
}

header .title {
  margin-left: 15px;
  font-weight: 700;
  color: rgb(71, 71, 71);
  font-size: 2.1rem;
  align-self: center;
}

.subheader {
  grid-row: -2 / -1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  transition: 0.2s;
  user-select: none;
}

.subheader:hover {
  cursor: pointer;
  background-color: rgb(241, 241, 241);
}

.indicator {
  grid-row: -2 / -1;
  width: 55px;
  height: 4.5px;
  background-color: var(--blue);
  justify-self: center;
  align-self: flex-end;
  position: absolute;
  border-radius: 1000px;
}

.signInOverlay {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #00000067;
}

.signInPopUp {
  position: relative;
  overflow: hidden;
  position: relative;
  height: 600px;
  width: 600px;
  border-radius: 15px;
  background-color: white;
  display: grid;
  grid-template-rows: 40px 100px 105px 50px 80px 105px 60px;
  padding: 0 150px;
}

.signInPopUp .close {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 35px;
  border-radius: 1000px;
  transition: 0.2s;
  padding: 5px;
}

.signInPopUp .close:hover {
  cursor: pointer;
  background-color: var(--light-grey);
}

.signInPopUp .logo {
  height: 25px;
  justify-self: center;
  align-self: flex-end;
}

.signInPopUp .title {
  font-size: 3.25rem;
  font-weight: 600;
  align-self: center;
}

.signInPopUp button {
  display: block;
  border-radius: 10000px;
  height: 3rem;
  border: none;
  font-size: 1.5rem;
  height: 40px;
  transition: 0.25s;
}

.signInPopUp button:hover {
  cursor: pointer;
  background-color: var(--blue-hover);
  border: 1px solid var(--blue-hover-border);
}

.signInPopUp .next:hover {
  background-color: var(--black-hover);
  border: none;
}
.signInPopUp .forgot:hover {
  background-color: rgb(226, 226, 226);
  border: 1px solid rgb(211, 211, 211);
}

.otherSignIn {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.otherSignIn .google {
  font-family: 'Google';
}
.otherSignIn .apple {
  font-family: 'Apple';
}

.signInPopUp .signInButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.5px;
  background-color: #fff;
  border: 1px solid rgb(211, 211, 211);
}

.signInButton img {
  width: 20px;
}

.signInPopUp input,
.signInPopUp textarea {
  position: relative;
  z-index: 2;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid rgb(211, 211, 211);
  height: 55px;
  font-size: 1.75rem;
  padding-left: 9px;
  padding-top: 11px;
  outline-color: var(--blue);
}

.signInPopUp .placeholder {
  color: rgb(101, 101, 101);
  left: 160px;
  top: 313px;
  z-index: 1;
  position: absolute;
  font-size: 1.75rem;
  transition: 0.2s;
  user-select: none;
  cursor: text;
}

.signInPopUp input:focus + .placeholder,
.placeholder.active {
  top: 303px;
  font-size: 1.25rem;
  color: var(--blue);
}

.placeholder.active {
  color: var(--light-black);
}

.signInPopUp .next {
  font-weight: 600;
  background-color: black;
  color: white;
}

.signInPopUp .forgot {
  color: black;
  font-weight: 600;
  background-color: #fff;
  border: 1px solid rgb(211, 211, 211);
}

.signInPopUp h2 {
  font-size: 1.5rem;
  color: var(--light-black);
}

.signInPopUp .signUpButton {
  color: var(--blue);
}

.signInPopUp .signUpButton:hover {
  cursor: pointer;
  text-decoration: underline;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1px;
  align-self: center;
  width: 100%;
  background-color: rgb(211, 211, 211);
}

.separator p {
  margin-bottom: 2.5px;
  text-align: center;
  font-size: 1.75rem;
  background-color: #fff;
  padding: 0 10px;
}

.signInPopUp h2 {
  align-self: flex-end;
}

.finish {
  height: 550px;
}

.finish form {
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: fit-content;
  width: 100%;
}

.finish .namePlaceholder {
  top: 158.5px;
}
.finish .userPlaceholder {
  position: absolute;
  color: rgb(101, 101, 101);
  left: 157.5px;
  font-size: 1.75rem;
  top: 238px;
}

.finish #username {
  padding: 0;
  padding-left: 25px;
}

.finish #username:focus + .userPlaceholder {
  color: var(--blue);
}

.finish #name:focus + .namePlaceholder {
  top: 150px;
  color: var(--blue);
}

.finish #name + .namePlaceholder.active {
  top: 150px;
}

.finish button:disabled {
  opacity: 80%;
}

.finish input.invalid,
.finish textarea.invalid {
  outline: 1px solid red;
}

#bio {
  font-family: 'Chirp', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
  min-height: 54px;
  padding-top: 8px;
  padding-bottom: 8px;
  resize: none;
  overflow: hidden;
}

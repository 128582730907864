.explorePage header {
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
}

.explorePage header .searchBar {
  width: 92.5%;
  justify-self: center;
}

.explorePage .searchBar {
  grid-column: 1 / -1;
}

.indicator.trending {
  left: 123px;
}

.indicator.popularAccounts {
  left: 422px;
}

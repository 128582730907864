.profilePage {
  position: relative;
}

.profilePage header {
  padding: 0 10px;
  display: flex;
  gap: 15px;
  align-items: center;
  height: 52.5px;
}

.profilePage header .info {
  height: 100%;
  padding: 6px 0;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

header .backContainer {
  border-radius: 1000px;
  transition: 0.2s;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

header .backContainer:hover {
  cursor: pointer;
  background-color: var(--light-grey);
}

.profilePage header .name {
  font-size: 2rem;
  font-weight: 700;
}

.profilePage header .chirps {
  font-size: 1.4rem;
  color: var(--light-black);
}

.profilePage header img {
  width: 22px;
}

.profilePage .banner {
  background-color: #989898;
  height: 200px;
  width: 100%;
}

.bannerImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.profilePage .profileInfo .profilePicContainer {
  width: 140px;
  height: 140px;
  transform: translateY(-75px);
  margin-bottom: -75px;
  background-color: #fff;
  border-radius: 1000px;
}

.profilePage .profileInfo .profilePicContainer > .profilePic {
  width: 132.5px;
  height: 132.5px;
}

.profilePage .profileInfo .profilePicContainer:hover {
  cursor: pointer;
}

.profileInfo {
  position: relative;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-bottom: 20px;
}

.profileInfo .accountNames {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 2.5px;
  margin-top: 5px;
}

.profileInfo .name {
  font-size: 2rem;
  font-weight: 800;
}

.at {
  color: var(--light-black);
  font-size: 1.55rem;
}

.bio {
  font-size: 1.525rem;
  line-height: 1.25;
}

.joinDate {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: var(--light-black);
  gap: 5px;
}

.joinDate img {
  width: 20px;
  filter: invert(39%) sepia(0%) saturate(3%) hue-rotate(216deg) brightness(95%)
    contrast(78%);
}

.followStats {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 1.5rem;
  color: var(--light-black);
}

.followStats .number {
  font-weight: 600;
  color: black;
}

.tabs {
  position: relative;
  display: flex;
  height: 52.5px;
  border-bottom: 1px solid var(--light-grey);
}

.profilePage .indicator.chirps {
  left: 47.5px;
}
.profilePage .indicator.replies {
  left: 197px;
}
.profilePage .indicator.media {
  left: 346.5px;
}
.profilePage .indicator.likes {
  left: 495.5px;
}

.profileButton {
  position: absolute;
  padding: 10px 20px;
  border-radius: 1000px;
  font-size: 1.5rem;
  font-weight: 700;
  border: 1px solid rgb(207, 207, 207);
  top: 15px;
  right: 15px;
}

.profileButton.finalizeButton {
  right: 130px;
}

.finalizeButton,
.followButton {
  background-color: black;
  color: white;
}

.finalizeButton:hover,
.followButton:hover {
  cursor: pointer;
  background-color: var(--black-hover);
}

.unfollowButton:hover,
.editButton:hover {
  cursor: pointer;
  background-color: var(--white-hover);
}

#bio {
  font-family: 'Chirp', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
  min-height: 54px;
  padding: 4px;
  resize: none;
  overflow: hidden;
}

input.name,
input.at {
  width: max-content;
}

input.name,
input.at,
textarea {
  border: 1px solid var(--light-grey);
  border-radius: 5px;
}

input.name:focus,
input.at:focus,
textarea:focus {
  outline: 1px solid var(--blue);
}

.usernameInputContainer {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: var(--light-black);
}

.profileInfo form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profilePicContainer {
  z-index: 2;
}

.bannerInputContainer {
  height: 200px;
  position: relative;
  z-index: 2;
  width: 100%;
}

#profilePicInput {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1000%;
  z-index: 4;
  top: 0px;
  left: 0px;
  color: transparent;
}

#bannerInput {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  z-index: 4;
  display: flex;
  align-items: center;
  color: transparent;
  transition: 0.15s;
  position: absolute;
}

.bannerInputContainer:hover .imageOverlay {
  background-color: rgba(0, 0, 0, 0.157);
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#profilePicInput:hover,
#bannerInput:hover {
  cursor: pointer;
}

.profilePage input::file-selector-button {
  display: none;
}

.profilePicContainer .inputIcon {
  opacity: 0;
  border-radius: 0;
  position: absolute;
  z-index: 3;
  filter: invert(100%);
  padding: 50px;
  transition: 0.15s;
}

.banner .inputIcon {
  opacity: 0;
  position: absolute;
  filter: invert(100%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.15s;
  z-index: 3;
}

.inputIcon:hover {
  cursor: pointer;
}

.profilePicContainer:hover .inputIcon,
.bannerInputContainer:hover .inputIcon {
  opacity: 1;
}

.profilePage .followers:hover,
.profilePage .following:hover {
  cursor: pointer;
  text-decoration: underline;
}

.chirpButton {
  font-size: 1.7rem;
  font-weight: 600;
  width: 233px;
  height: 52px;
  flex-shrink: 0;
  background-color: var(--blue);
  color: white;
  border: none;
  border-radius: 1000px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
  transition: 0.15s;
}

.chirpButton:hover {
  cursor: pointer;
  background-color: var(--blue-hover-pure);
}

.chirpModule .chirpButton {
  font-size: 1.6rem;
  width: 75px;
  height: 37px;
}

.chirpButton:disabled {
  opacity: 50%;
}

.chirpButton:disabled:hover {
  cursor: auto;
  background-color: var(--blue);
}

.chirp {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 75px 1fr;
  grid-template-rows: 20px 1fr 40px;
  border-bottom: 1px solid var(--light-grey);
  padding-top: 10px;
  transition: 0.2s;
  white-space: pre-wrap;
}

.chirp:hover {
  cursor: pointer;
  background-color: var(--white-hover);
}

.chirp:has(.moreMenu:hover) {
  background-color: transparent;
}

.chirpHeader {
  align-self: center;
  grid-row: 1 / 2;
  grid-column: 2 / -1;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.reChirpHeader {
  position: relative;
  top: 7.5px;
  bottom: 7.5px;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 700;
  color: var(--light-black);
  gap: 5px;
}

.reChirpHeader img {
  width: 12.5px;
}

.reChirped.chirp .chirpInfo {
  margin-top: 10px;
}
.reChirped.chirp .chirpSubmit {
  margin-top: 12.5px;
}
.chirpInfo {
  grid-row: 1 / 2;
  grid-column: 2 / -1;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: var(--light-black);
  gap: 5px;
}

.chirpInfo .name {
  font-weight: 700;
  color: rgb(30, 30, 30);
}

.chirpInfo .name:hover {
  text-decoration: underline;
}

.chirpInfo .separator {
  width: 2px;
  height: 2px;
  background-color: var(--light-black);
}

.chirpInfo .settingContainer {
  margin-left: auto;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  border-radius: 100px;
}

.chirpInfo .settingContainer > img {
  width: 80%;
  opacity: 70%;
}

.settingContainer:hover {
  cursor: pointer;
}

.chirpSubmit {
  margin-top: 2px;
  grid-row: 2 / 3;
  grid-column: 2 / -1;
  font-size: 1.5rem;
  padding-right: 15px;
  line-height: 1.25;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: hidden;
  word-wrap: break-word;
}

.chirpSubmit > .chirpImage {
  border-radius: 15px;
  border: 1px solid var(--light-grey);
}

.chirpIcons {
  grid-row: -2 / -1;
  grid-column: -2 / -1;
  display: flex;
  justify-content: space-around;
  align-self: center;
  margin-left: -7.5px;
}

.hashtag:hover {
  cursor: pointer;
  text-decoration: underline;
}

.chirpIcons .icon {
  display: flex;
  align-items: center;
  color: var(--light-black);
  width: fit-content;
  margin-right: auto;
}

.chirpIcons .icon .container {
  border-radius: 1000px;
  transition: 0.2s;
}

.chirpIcons .icon p {
  font-size: 1.3rem;
}

.chirpIcons .icon img {
  width: 32px;
  padding: 7.5px;
  opacity: 70%;
}

.chirpIcons .icon:hover .container,
.chirpInfo .settingContainer:hover {
  cursor: pointer;
  background-color: var(--blue-hover-white);
}

.icon.likes .container > img {
  transition: 0.15s;
}

.chirpIcons .icon.likes:hover .container {
  background-color: #ffcfcf7e;
}

.chirpIcons .icon.likes:hover {
  color: #ff2f60;
}

/* .chirpIcons .icon.likes:hover .container > img {
  filter: invert(29%) sepia(86%) saturate(1365%) hue-rotate(336deg)
    brightness(110%) contrast(106%);
} */

.chirpIcons .icon.reChirp:hover {
  color: hsl(130, 80%, 39%);
}

.chirpIcons .icon.reChirp:hover .container {
  background-color: hsla(118, 68%, 81%, 0.494);
}

.chirpIcons .icon.reChirp:hover .container > img {
  filter: invert(82%) sepia(47%) saturate(4064%) hue-rotate(61deg)
    brightness(72%) contrast(89%);
}

.chirpIcons .icon.chat:hover {
  color: var(--blue);
}

.chirpIcons .icon.chat:hover .container > img,
.chirpIcons .icon.share:hover .container > img,
.chirpInfo .settingContainer:hover > img {
  filter: invert(23%) sepia(87%) saturate(3655%) hue-rotate(267deg)
    brightness(89%) contrast(116%);
}

.chirpIcons .icon.likes .container > img.fill {
  animation-name: likeAnimation;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.chirpIcons .icon.reChirp .container > img.fill {
  animation-name: reChirpAnimation;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  filter: invert(46%) sepia(78%) saturate(632%) hue-rotate(81deg)
    brightness(98%) contrast(94%);
}

@keyframes reChirpAnimation {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes likeAnimation {
  50% {
    transform: scale(130%);
  }
  100% {
    transform: scale(100%);
  }
}

.chirpPage header {
  height: 52.5px;
  display: flex;
  border: none;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

header .backContainer {
  border-radius: 1000px;
  transition: 0.2s;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

header .backContainer:hover {
  cursor: pointer;
  background-color: var(--light-grey);
}

.chirpPage .backContainer img {
  width: 22px;
}

.pageChirp.chirp {
  grid-template-columns: 1fr;
  grid-template-rows: 75px 1fr 50px 50px 50px;
  display: grid;
  padding: 0 15px;
  border: none;
  margin-bottom: 15px;
}

.pageChirp.chirp:hover {
  cursor: auto;
  background-color: transparent;
}

.profileArea {
  width: 100%;
  grid-row: 1 / 2;
  display: flex;
  align-items: center;
  gap: 12.5px;
}

.profileArea .profilePicContainer:hover {
  cursor: pointer;
}
.profileArea .chirpInfo .name:hover {
  cursor: pointer;
}
.profileArea .chirpInfo .at {
  user-select: none;
}

.profileArea .chirpInfo {
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  align-items: flex-start;
}

.profileArea .profilePicContainer {
  width: 48px;
  height: 48px;
}

.profileArea .settingContainer {
  margin-left: auto;
}
.pageChirp .chirpSubmit .chirpWords {
  font-size: 1.75rem;
}

.pageChirp .time .separator {
  width: 2px;
  height: 2px;
  background-color: var(--light-black);
}

.pageChirp .chirpStats {
  display: flex;
  align-items: center;
  gap: 20px;
}

.pageChirp .countContainer {
  display: flex;
  font-size: 1.4rem;
  gap: 4px;
  color: var(--light-black);
}

.countContainer .count {
  font-weight: 700;
  color: black;
}

.pageChirp .chirpSubmit {
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  padding: 0;
  gap: 11px;
}
.pageChirp .time {
  grid-column: 1 / -1;
  grid-row: 3 / 4;
  border-bottom: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: var(--light-black);
  gap: 5px;
}
.pageChirp .chirpStats {
  grid-column: 1 / -1;
  grid-row: 4 / 5;
  border-bottom: 1px solid var(--light-grey);
  width: 100%;
}
.pageChirp .chirpIcons {
  width: 100%;
  height: 100%;
  grid-column: 1 / -1;
  grid-row: 5 / 6;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: center;
  margin: 0;
  border-bottom: 1px solid var(--light-grey);
}

.pageChirp .chirpIcons .icon {
  border-radius: 1000px;
  justify-self: center;
  height: 100%;
  margin: 0;
}

.pageChirp .chirpIcons .icon .container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 2.5px;
}

.pageChirp .container img {
  width: 100%;
}

.hashtag:hover {
  cursor: pointer;
  text-decoration: underline;
}
